import * as React from "react";
import Stack from "@mui/material/Stack";
import Badge from "@mui/material/Badge";
import MailIcon from "@mui/icons-material/Mail";
import Popover from "@mui/material/Popover";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Box, Divider, Modal, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { io } from "socket.io-client";
import { indexOf } from "lodash";
import { useState } from "react";
import { toast } from "react-toastify";
import { api_url } from "../../apiUtils";
import axios from "axios";
import { useEffect } from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useAppStore } from "../../appStore";
import moment from "moment";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#ff6d00",
    color: "#ffffff",
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
      color: "#ff6d00",
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(1.6)",
      opacity: 0,
    },
  },
}));

export default function Notification() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedMessage, setSelectedMessage] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [messages, setMessages] = React.useState([]);
  const { isMessageOpened, setIsMessageOpened } = useAppStore();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenModal = (message) => {
    setSelectedMessage(message);
    setOpenModal(true);
    handleClose();
    setIsMessageOpened(true); // Assuming you want to set it to true when any message is opened
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const toggleMessageOpened = (index) => {
    const updatedMessages = messages.map((message, i) => {
      if (i === index) {
        // Check if the message is already opened
        if (!message.isMessageOpened) {
          const updatedMessage = {
            ...message,
            isMessageOpened: true, // Set to true if not already opened
          };
          sessionStorage.setItem(
            `message_${i}`,
            JSON.stringify(updatedMessage)
          );
          return updatedMessage;
        }
      }
      return message;
    });
    setMessages(updatedMessages);
  };

  const openedMessagesCount = messages.filter(
    (message) => message.isMessageOpened
  ).length;
  const badgeContent = messages.length - openedMessagesCount;

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const result = sessionStorage.getItem("accesstoken");
  const headers = {
    accessToken: result,
  };

  const fetchMessages = async () => {
    try {
      const response = await axios.get(`${api_url}user/inbox/message`, {
        headers,
      });
      const data = response.data;
      console.log(data?.messages);
      const updatedMessages = data?.messages.map((message, index) => {
        const savedMessage = sessionStorage.getItem(`message_${index}`);
        const isMessageOpened = savedMessage
          ? JSON.parse(savedMessage).isMessageOpened
          : false;
        return {
          ...message,
          isMessageOpened,
        };
      });
      setMessages(updatedMessages);
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  useEffect(() => {
    fetchMessages();
  }, []);

  return (
    <Stack
      spacing={4}
      direction="row"
      sx={{ color: "action.active", cursor: "pointer" }}
    >
      {badgeContent !== 0 ? (
        <StyledBadge
          color="error"
          badgeContent={badgeContent}
          showZero
          onClick={handleClick}
        >
          <MailIcon sx={{ color: { xs: "#e0e0e0", md: "#757575" } }} />
        </StyledBadge>
      ) : (
        <MailIcon
          sx={{ color: { xs: "#e0e0e0", md: "#757575" } }}
          onClick={handleClick}
        />
      )}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{ mt: 3 }}
      >
        <Card sx={{ width: "240px" }}>
          <Box
            sx={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                p: 1,
                fontWeight: "bold",
                color: "#1e2466",
              }}
            >
              Messages
              {/* ({messages.length}) */}
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                p: 1,
                fontWeight: "bold",
                color: "#ff5722",
              }}
            >
              Unread ({badgeContent})
            </Typography>
          </Box>
          <Box
            sx={{
              mr: 0.5,
              maxHeight: "250px", // Adjust the height as needed
              overflowY: "auto",
              "&:hover": {
                overflowY: "auto",
              },
              "&::-webkit-scrollbar": {
                width: "6px",
                display: "none",
              },
              "&:hover::-webkit-scrollbar": {
                display: "block",
                background: "transparent",
              },
              "&::-webkit-scrollbar-thumb": {
                background: `#e9e9e9`,
                borderRadius: "4px",
              },
            }}
          >
            {messages.map((message, index) => (
              <CardContent
                key={index}
                sx={{
                  p: "3px 2px 2px 10px",
                  m: 0.5,
                  mt: 1,
                  background: "#f5f5f5",
                  borderRadius: 2,
                  cursor: "pointer",
                  borderLeft: !message.isMessageOpened && "4px solid #039be5",
                }}
                onClick={() => {
                  toggleMessageOpened(index);
                  handleOpenModal(message);
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: message.isMessageOpened ? "#757575" : "#212121",
                      fontWeight: !message.isMessageOpened && "bold",
                    }}
                  >
                    {message?.heading}
                  </Typography>
                  <Typography sx={{ fontSize: "10px", pr: 1 }}>
                    {" "}
                    {message?.createdAt
                      ?.slice(0, 10)
                      .split("-")
                      .reverse()
                      .join("/")}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant="caption"
                    component="div"
                    sx={{
                      width: "100%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      color: message.isMessageOpened ? "#9e9e9e" : "#212121",
                    }}
                  >
                    {message?.message}
                  </Typography>
                  <Typography
                    sx={{ fontSize: "10px", pr: 1, whiteSpace: "nowrap" }}
                  >
                    {" "}
                    {moment(message?.createdAt).format("LT")}
                  </Typography>
                  {/* {message.isMessageOpened && (
                    <DeleteOutlineIcon sx={{ color: "red", pr: 1 }} />
                  )} */}
                </Box>
              </CardContent>
            ))}
          </Box>
          <Divider />
        </Card>
      </Popover>

      <Modal
        open={openModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "90%", lg: 400 },
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 2,
            p: 4,

            textAlign: "justify",
            textJustify: "inter-word",
          }}
        >
          <Box
            sx={{ display: "flex", justifyContent: "flex-end", mt: -3, mr: -3 }}
          >
            <CloseIcon
              onClick={handleCloseModal}
              sx={{
                color: "red",
                cursor: "pointer",
                background: "#ffebee",
                borderRadius: 1,
              }}
            />
          </Box>
          <Typography
            sx={{
              fontSize: "12px",
              ml: 2,
              color: "#9e9e9e",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            {" "}
            {selectedMessage?.createdAt
              ?.slice(0, 10)
              .split("-")
              .reverse()
              .join("/")}
            ,
            <p style={{ fontSize: "12px", paddingLeft: 5, color: "#9e9e9e" }}>
              {" "}
              {moment(selectedMessage?.createdAt).format("LT")}
            </p>
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              sx={{
                color: "#1e2466",
              }}
            >
              {selectedMessage && selectedMessage?.heading}
            </Typography>
          </Box>

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {selectedMessage && selectedMessage?.message}
          </Typography>
        </Box>
      </Modal>
    </Stack>
  );
}
