// ConfirmationDialog.js

import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { useAppStore } from "../../appStore";
import ShareIcon from "@mui/icons-material/Share";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { toast } from "react-toastify";

const ConfirmationDialog = () => {
  const { successDialog, setSuccessDialog, selectedGame, setSelectedGame } =
    useAppStore();
  console.log(selectedGame);

  const handleShare = () => {
    toast.success("shared successfully.");
    setSuccessDialog(false);
  };
  return (
    <Dialog open={successDialog}>
      <Box sx={{ width: { md: "400px" } }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: -3,
          }}
        >
          <DialogTitle sx={{ fontWeight: "bold" }}>Confirmation</DialogTitle>
          <DialogActions>
            <Button
              onClick={() => {
                setSuccessDialog(false);
                setSelectedGame({});
              }}
              color="error"
            >
              <CloseIcon />
            </Button>
          </DialogActions>
        </Box>
        <DialogContent sx={{ textAlign: "center" }}>
          <Box
            sx={{
              width: "200px",
              height: "200px",
              background: "white",
              mx: "auto",
              objectFit: "contain",
            }}
          >
            <img
              src="/images/success.gif"
              alt=""
              width={"100%"}
              style={{ display: "block", margin: "0 auto" }}
            />
          </Box>

          <Typography>
            Your{" "}
            <span
              style={{ color: "red", fontWeight: "bold", fontSize: "18px" }}
            >
              {selectedGame.gameName}
            </span>{" "}
            submission has been confirmed successfully!
          </Typography>

          {/* <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 1,
            }}
          >
            <Button
              variant="outlined"
              size="small"
              sx={{
                display: "flex",
                alignItems: "center",
                textTransform: "capitalize",
              }}
              onClick={handleShare}
            >
              <ShareIcon sx={{ color: "#bdbdbd" }} />{" "}
              <span style={{ margin: "0px 5px" }}>whatsapp</span>
              <WhatsAppIcon sx={{ color: "green" }} />
            </Button>
          </Box> */}
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default ConfirmationDialog;
