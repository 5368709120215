// Loader.js

import React from "react";
import { Box, Dialog, Modal } from "@mui/material";
import { useAppStore } from "../../appStore";
import { TailSpin } from "react-loader-spinner";
import "./loader.css";
const Loader = () => {
  const { loading } = useAppStore();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    p: 2,
    borderRadius: 2
  };

  return (
    <Modal open={loading} sx={{ background: "transparent" }}>
      <Box sx={style}>
        <TailSpin color="orange" radius="0" strokeWidth={3} height={"50px"} width={"50px"} />
      </Box>
    </Modal>
  );
  // return (
  //   <div id="loading">
  //     <div className="dice">
  //       <div className="front">1</div>
  //       <div className="back">6</div>
  //       <div className="left">2</div>
  //       <div className="right">5</div>
  //       <div className="top">3</div>
  //       <div className="bottom">4</div>
  //     </div>
  //     <p>LOADING...</p>
  //   </div>
  // );
};

export default Loader;
