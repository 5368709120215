import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const result = sessionStorage.getItem("accesstoken");
  if (!result) {
    return <Navigate to="/" />;
  }
  return children;
};
export default ProtectedRoute;
