// ErrorPage.js

import { Box } from "@mui/material";
import React from "react";

const ErrorPage = () => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <div style={{ textAlign: "center" }}>
        <img src="/images/network.gif" />
        <h4>No Internet Connection</h4>
        <h4>Try these steps to get back online:</h4>
      </div>
      <Box sx={{ textAlign: "center", color: "gray" }}>
        <p>☑️Check your modem and router</p>
        <p>☑️Reconnect to Wi-Fi</p>
        <p style={{ color: "red" }}>ERR_INTERNET_DISCONNECTED</p>
      </Box>
    </Box>
  );
};

export default ErrorPage;
