// import { useAppStore } from "../../appStore";

// export function GameTimeCalculation() {
//   const { selectedGame, profileData } = useAppStore();
//   let num = 4;

//   const gameTiming = () => {
//     const today = new Date();
//     const currentDay = today.getDay();
//     // An array to map the numeric representation to the actual day names
//     const daysOfWeek = [
//       "Sunday",
//       "Monday",
//       "Tuesday",
//       "Wednesday",
//       "Thursday",
//       "Friday",
//       "Saturday",
//     ];
//     // Get the name of the current day
//     const dayName = daysOfWeek[currentDay];
//     if (Object.keys(selectedGame).length === 0) {
//     } else {
//       const responseDayData = selectedGame.weekTimings.find(
//         (dayData) => dayData?.day === dayName
//       );
//       console.log("responseDayData", responseDayData);
//       console.log("selectedGame", selectedGame);
//       if (responseDayData) {
//         const openTime = responseDayData.time || "00:00";
//         const customerOpenTime =
//           selectedGame?.preClosingTimeOpen[num]?.openingTime || 0;
//         const [hours, minutes] = openTime.split(":").map(Number);
//         // Subtract customer open time in minutes
//         let resultMinutes = minutes - customerOpenTime;
//         console.log(
//           "    let resultMinutes = minutes - customerOpenTime;",
//           minutes,
//           customerOpenTime
//         );
//         let resultHours = hours;
//         // Adjust hours if needed
//         if (resultMinutes < 0) {
//           resultHours -= 1;
//           resultMinutes += 60;
//         }
//         const resultTime = `${String(resultHours).padStart(2, "0")}:${String(
//           resultMinutes
//         ).padStart(2, "0")}`;
//         // close time
//         const closeTime = responseDayData.time || "00:00";
//         const customerCloseTime =
//           selectedGame?.preClosingTimeClose[num]?.closingTime || 0;
//         console.log("customerCloseTime", customerCloseTime);
//         const [hours1, minutes1] = closeTime.split(":").map(Number);
//         // Subtract customer open time in minutes
//         let resultMinutes1 = minutes1 - customerCloseTime;
//         let resultHours1 = hours1;
//         // Adjust hours if needed
//         if (resultMinutes1 < 0) {
//           resultHours1 -= 1;
//           resultMinutes1 += 60;
//         }
//         const resultTime1 = `${String(resultHours1).padStart(2, "0")}:${String(
//           resultMinutes1
//         ).padStart(2, "0")}`;
//         // close time
//         //   setOpenTimeGame9(resultTime);
//         let time = {
//           openTime: resultTime,
//           closeTime: resultTime1,
//         };
//         return time;
//       } else {
//         console.log("Holiday.");
//         return "00:00";
//       }
//     }
//   };
//   return {
//     gameTiming,
//   };
// }
import { useAppStore } from "../../appStore";

export function GameTimeCalculation() {
  const { selectedGame, profileData } = useAppStore();
  let num = 4;

  const gameTiming = () => {
    const today = new Date();
    const currentDay = today.getDay();
    // An array to map the numeric representation to the actual day names
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    // Get the name of the current day
    const dayName = daysOfWeek[currentDay];
    if (Object.keys(selectedGame).length === 0) {
    } else {
      const responseDayData = selectedGame.weekTimings.find(
        (dayData) => dayData?.day === dayName
      );
      console.log(responseDayData);
      if (responseDayData) {
        // close time
        const closeTime = responseDayData.time || "00:00";
        const customerCloseTime =
          selectedGame?.preClosingTime[num]?.closingTime || 0;
        const [hours1, minutes1] = closeTime.split(":").map(Number);
        // Subtract customer open time in minutes
        let resultMinutes1 = minutes1 - customerCloseTime;
        let resultHours1 = hours1;
        // Adjust hours if needed
        if (resultMinutes1 < 0) {
          resultHours1 -= 1;
          resultMinutes1 += 60;
        }
        const resultTime1 = `${String(resultHours1).padStart(2, "0")}:${String(
          resultMinutes1
        ).padStart(2, "0")}`;
        // close time
        //   setOpenTimeGame9(resultTime);
        let time = resultTime1;
        return time;
      } else {
        console.log("Holiday.");
        return "00:00";
      }
    }
  };
  return {
    gameTiming,
  };
}
