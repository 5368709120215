import { Box } from "@mui/material";
import React from "react";
import Sidebar from "../../components/sidebar/sidebar";
import { Helmet } from "react-helmet";
import CPNumbers from "../../components/Menus/menuNumbers/CP";

function CP() {
  return (
    <>
      <Helmet>
        <title>Satta-Matka ( M-India ) | CP</title>
      </Helmet>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Sidebar />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 1,
            background: `url(${process.env.PUBLIC_URL}/images/logo-background.png) center/250px no-repeat fixed `,
            minHeight: "100vh",
          }}
        >
          <Box height={{ xs: 60, md: 90 }} />

          {/* games */}
          <Box mx={{ xs: 1, sm: 5, md: 10 }}>
            <CPNumbers />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default CP;
