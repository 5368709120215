import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";
import { useEffect, useState, Suspense, lazy } from "react";
import ProtectedRoute from "./protectedRoute";
import ErrorPage from "./components/Misc/Error/error";
import PageNotFound from "./components/Misc/404page/pageNotFound";
import WalletStatementsPage from "./pages/WalletStatements/WalletStatementsPage";
import CP from "./pages/menus/CP";

// Lazy load the components
const Login = lazy(() => import("./pages/login/login"));
const Register = lazy(() => import("./pages/register/register"));
const Home = lazy(() => import("./pages/home/home"));
const Profile = lazy(() => import("./pages/profile/profile"));
const Wallet = lazy(() => import("./pages/wallet/wallet"));
const DepositReport = lazy(() => import("./pages/wallet/depositReport"));
const WithdarwRequest = lazy(() => import("./pages/wallet/withdrawRequest"));
const ChooseMenus = lazy(() => import("./pages/menus/selectMenus"));
const Result = lazy(() => import("./pages/result/result"));
const Bulk90 = lazy(() => import("./pages/menus/Bulk 90"));
const Game90 = lazy(() => import("./pages/menus/Game90"));
const Game9 = lazy(() => import("./pages/menus/Game9"));
const AllInOne = lazy(() => import("./pages/menus/AllInOne"));
const SinglePana = lazy(() => import("./pages/menus/SinglePana"));
const DoublePana = lazy(() => import("./pages/menus/DoublePana"));
const TriplePana = lazy(() => import("./pages/menus/TriplePana"));
const TotalMenu = lazy(() => import("./pages/menus/TotalMenu"));
const DynamicMenuPage = lazy(() => import("./pages/menus/DynamicMenuPage"));
const JodiFamilyPana = lazy(() => import("./pages/menus/JodiFamilyPana"));
const FamilyPana = lazy(() => import("./pages/menus/FamilyPana"));
const Adhik = lazy(() => import("./pages/menus/Adhik"));
const ResultDownload = lazy(() => import("./pages/result/ResultDownload"));
const History = lazy(() => import("./pages/History/History"));
const HolidayList = lazy(() => import("./pages/HolidayList/HolidayList"));
const ZeroPana = lazy(() => import("./pages/menus/ZeroPana"));
const RP = lazy(() => import("./pages/menus/RP"));
const MP = lazy(() => import("./pages/menus/MP"));
const Game1000 = lazy(() => import("./pages/menus/Game1000"));
const Game10000 = lazy(() => import("./pages/menus/Game10000"));
const CutAkda = lazy(() => import("./pages/menus/CutAkda"));

function App() {
  const [online, setOnline] = useState(window.navigator.onLine);

  // vendor online or offline using window event
  useEffect(() => {
    const handleOnline = () => setOnline(true);
    const handleOffline = () => setOnline(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  if (!online) {
    return <ErrorPage />;
  }

  return (
    <>
      <BrowserRouter>
        <Helmet defaultTitle="Satta-Matka ( M-India )">
          <link rel="icon" href="/sm-logo.png" />
        </Helmet>
        <ToastContainer
          position="top-center"
          theme="dark"
          hideProgressBar={true}
          autoClose={2000}
          transition={Slide}
        />

        <Suspense
          fallback={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Loading...
            </div>
          }
        >
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route
              path="/home/menus"
              element={
                <ProtectedRoute>
                  <Home />
                </ProtectedRoute>
              }
            />
            {/* games */}
            <Route
              path="/game/play/Bulk 90"
              element={
                <ProtectedRoute>
                  <Bulk90 />
                </ProtectedRoute>
              }
            />
            <Route
              path="/game/play/Game 90"
              element={
                <ProtectedRoute>
                  <Game90 />
                </ProtectedRoute>
              }
            />
            <Route
              path="/game/play/Game 9"
              element={
                <ProtectedRoute>
                  <Game9 />
                </ProtectedRoute>
              }
            />
            <Route
              path="/game/play/All in one"
              element={
                <ProtectedRoute>
                  <AllInOne />
                </ProtectedRoute>
              }
            />
            <Route
              path="/game/play/SP"
              element={
                <ProtectedRoute>
                  <SinglePana />
                </ProtectedRoute>
              }
            />
            <Route
              path="/game/play/DP"
              element={
                <ProtectedRoute>
                  <DoublePana />
                </ProtectedRoute>
              }
            />
            <Route
              path="/game/play/TP"
              element={
                <ProtectedRoute>
                  <TriplePana />
                </ProtectedRoute>
              }
            />
            <Route
              path="/game/play/Total"
              element={
                <ProtectedRoute>
                  <TotalMenu />
                </ProtectedRoute>
              }
            />
            <Route
              path="/game/play/Jodi Family"
              element={
                <ProtectedRoute>
                  <JodiFamilyPana />
                </ProtectedRoute>
              }
            />
            <Route
              path="/game/play/Pana Family"
              element={
                <ProtectedRoute>
                  <FamilyPana />
                </ProtectedRoute>
              }
            />
            <Route
              path="/game/play/Adhik"
              element={
                <ProtectedRoute>
                  <Adhik />
                </ProtectedRoute>
              }
            />
            <Route
              path="/game/play/ZP"
              element={
                <ProtectedRoute>
                  <ZeroPana />
                </ProtectedRoute>
              }
            />
            <Route
              path="/game/play/RP"
              element={
                <ProtectedRoute>
                  <RP />
                </ProtectedRoute>
              }
            />
            <Route
              path="/game/play/MP"
              element={
                <ProtectedRoute>
                  <MP />
                </ProtectedRoute>
              }
            />
            <Route
              path="/game/play/Game 1000"
              element={
                <ProtectedRoute>
                  <Game1000 />
                </ProtectedRoute>
              }
            />
            <Route
              path="/game/play/Game 10000"
              element={
                <ProtectedRoute>
                  <Game10000 />
                </ProtectedRoute>
              }
            />
            <Route
              path="/game/play/Cut Akda"
              element={
                <ProtectedRoute>
                  <CutAkda />
                </ProtectedRoute>
              }
            />
            <Route
              path="/game/play/CP"
              element={
                <ProtectedRoute>
                  <CP />
                </ProtectedRoute>
              }
            />
            {/* ----end-games----- */}

            {/* Dynamic Menus */}
            <Route
              path="/play/:name"
              element={
                <ProtectedRoute>
                  <DynamicMenuPage />
                </ProtectedRoute>
              }
            />
            {/*------- Dynamic Menus end ------*/}

            <Route
              path="/wallet/manage-points"
              element={
                <ProtectedRoute>
                  <Wallet />
                </ProtectedRoute>
              }
            />
            <Route
              path="/wallet/deposit-reports"
              element={
                <ProtectedRoute>
                  <DepositReport />
                </ProtectedRoute>
              }
            />
            <Route
              path="/wallet/withdraw-reports"
              element={
                <ProtectedRoute>
                  <WithdarwRequest />
                </ProtectedRoute>
              }
            />
            <Route
              path="/wallet-statements"
              element={
                <ProtectedRoute>
                  <WalletStatementsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/result"
              element={
                // <ProtectedRoute>
                <Result />
                // </ProtectedRoute>
              }
            />
            <Route
              path="/download_results"
              element={
                // <ProtectedRoute>
                <ResultDownload />
                // </ProtectedRoute>
              }
            />

            <Route
              path="/profile"
              element={
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              }
            />

            <Route
              path="/settings/select-menus"
              element={
                <ProtectedRoute>
                  <ChooseMenus />
                </ProtectedRoute>
              }
            />

            <Route
              path="/history"
              element={
                // <ProtectedRoute>
                <History />
                // </ProtectedRoute>
              }
            />
            <Route
              path="/holiday-list"
              element={
                <ProtectedRoute>
                  <HolidayList />
                </ProtectedRoute>
              }
            />

            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
}

export default App;
