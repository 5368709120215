import { useEffect, useState } from "react";
import { api_url } from "../../apiUtils";
import axios from "axios";
import getWalletBalance from "./GetWalletBalance"; // Assuming this is correctly imported
import useWalletBalance from "./GetWalletBalance"; // Assuming this is correctly imported
import { useAppStore } from "../../appStore";

// Custom hook for making the API call
const useCalculateAmount = () => {
  const [calculation, setCalculation] = useState(null);
  const fetchBalance = useWalletBalance();

  // useEffect(() => {
  //   if (calculation) {
  //     fetchBalance();
  //     console.log("fetch Balance", walletBalance);
  //   }
  // }, [calculation, walletBalanceUpdated]);

  const calculateAmount = async (requestData) => {
    console.log(requestData);

    try {
      const response = await axios.post(`${api_url}game/cal`, requestData, {
        headers: {
          accesstoken: sessionStorage.getItem("accesstoken"),
        },
      });

      const data = response.data.calculation;
      console.log("calculation", data);
      await fetchBalance();
      setCalculation(data);
    } catch (error) {
      console.log(error.message);
    }
  };

  return { calculateAmount };
};

export default useCalculateAmount;
