import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import HomeIcon from "@mui/icons-material/Home";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import PersonIcon from "@mui/icons-material/Person";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import {
  Divider,
  Grid,
  Menu,
  MenuItem,
  Modal,
  Paper,
  TextField,
} from "@mui/material";
import { useAppStore } from "../../appStore";
import { useEffect } from "react";
import axios from "axios";
import { api_url } from "../../apiUtils";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import WidgetsIcon from "@mui/icons-material/Widgets";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ProfileBadge from "../profile/profileBadge";
import HistoryIcon from "@mui/icons-material/History";
import EventIcon from "@mui/icons-material/Event";
import Notification from "../notification/Notification";
import CasinoIcon from "@mui/icons-material/Casino";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { toast } from "react-toastify";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import RequestDialog from "../../components/Wallet/depositRequest/depositDialog";
const drawerWidth = 260;

const menuItems = [
  {
    text: "Play Game",
    icon: <CasinoIcon />,
    path: "/home/menus",
  },
  {
    text: "Profile",
    icon: <PersonIcon />,
    path: "/profile",
  },
  {
    path: "/settings/select-menus",
    text: "Menus / Games",
    icon: <WidgetsIcon />,
  },
  {
    text: "Wallet [ D-R-P ]",
    icon: <AccountBalanceWalletIcon />,
    path: "/wallet/manage-points",
  },
  {
    path: "/history",
    text: "History",
    icon: <HistoryIcon />,
  },
  {
    text: "Result",
    icon: <EmojiEventsIcon />,
    path: "/result",
  },

  {
    path: "/holiday-list",
    text: "Holiday List",
    icon: <EventIcon />,
  },
  {
    path: "/wallet-statements",
    text: "Wallet Statements",
    icon: <ReceiptLongIcon />,
  },
  // {
  //   text: "Settings",
  //   icon: <SettingsIcon />,

  //   subItems: [
  //     {
  //       path: "/settings/select-menus",
  //       text: "Menus / Games",
  //       icon: <WidgetsIcon />,
  //     },

  //     // Add more sub-items as needed
  //   ],
  // },
];

function Sidebar(props) {
  const { window } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const [walletMenuAnchor, setWalletMenuAnchor] = useState(null);
  const {
    profileData,
    walletBalance,
    setWalletBalance,
    setAddPointOpen,
    setCreditLimit,
    addPointOpen,
    walletBalanceUpdated,
  } = useAppStore();
  const [selectedIndex, setSelectedIndex] = React.useState(-1);

  // const [walletBalance, setWalletBalance] = useState();
  // const toggleSubItems = (index) => {
  //   if (selectedIndex === index) {
  //     setSelectedIndex(-1); // Close sub-items when the same main item is clicked again
  //   } else {
  //     setSelectedIndex(index); // Open sub-items
  //   }
  // };
  let tokenStr = sessionStorage.getItem("accesstoken");

  const getWalletPoints = async () => {
    const response = await axios.get(`${api_url}wallet/balance`, {
      headers: { accesstoken: tokenStr },
    });
    const request = response.data;
    setWalletBalance(request?.walletPoint?.balance);
    setCreditLimit(request?.creditLimit);
    console.log("wallet", walletBalanceUpdated, request);
    return response.data;
  };

  useEffect(() => {
    getWalletPoints();
    // fetchMessages();
  }, [walletBalanceUpdated]);

  // const fetchMessages = async () => {
  //   try {
  //     const response = await axios.get(`${api_url}user/inbox/message`, {
  //       headers: { accesstoken: tokenStr },
  //     });
  //     const data = response.data;
  //     console.log(data);
  //   } catch (error) {
  //     console.error("Error: ", error);
  //   }
  // };

  // drawer
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleItemClick = (index) => {
    setSelectedItem(index);
  };

  const handleOpenWalletMenu = (event) => {
    setWalletMenuAnchor(event.currentTarget);
  };

  const handleCloseWalletMenu = () => {
    setWalletMenuAnchor(null);
  };
  const handleListItemClick = (index) => {
    if (selectedIndex === index) {
      setSelectedIndex(-1); // Close sub-items when the same sub-item is clicked again
    } else {
      setSelectedIndex(index); // Open sub-items
    }
  };

  useEffect(() => {
    // Find the index that corresponds to the current route
    const index = menuItems.findIndex(
      (item) => item.path === location.pathname
    );
    setSelectedIndex(index);
  }, [location.pathname]);

  const handleManagePointMenu = () => {
    navigate("/wallet/manage-points");
    handleCloseWalletMenu();
  };

  const handleDepositPointMenu = () => {
    navigate("/wallet/deposit-reports");
  };
  const handleWithdrawPointMenu = () => {
    navigate("/wallet/withdraw-reports");
  };

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const drawer = (
    <div
      style={{
        backgroundColor: "rgba(23 ,27 ,68, 0.95)", // Semi-transparent background color
        height: "100%",
        backdropFilter: "blur(10px)", // Add the backdrop-filter for the glass effect
      }}
    >
      <div
        style={{
          background: `url(${process.env.PUBLIC_URL}/images/sidebar-img.png) `, // Set the top 20 pixels background color to red
          height: "64px",
          width: "100%",
          position: "absolute",
          backgroundSize: "cover",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src="/images/sm-logo.png"
          alt="Logo"
          style={{
            height: "80px",
            width: "80px",
            top: "40px",
            position: "relative",
            backgroundColor: "#1b1c1c",
            borderRadius: "50%",
            filter: "contrast(130%)",
            padding: "5px",
            cursor: "pointer",
          }}
          onClick={() => navigate("/home/menus")}
        />
      </div>

      <Toolbar />
      <Typography
        sx={{
          color: "#FFD80A",
          mt: { xs: "55px", md: "50px" },
          textAlign: "center",
          letterSpacing: 1,
          fontSize: "20px",
        }}
      >
        SM-India
      </Typography>
      <Box my={2}>{/* <Divider sx={{ background: "#616161" }} /> */}</Box>
      <Box
        sx={{
          backdropFilter: "blur(10px)", // Apply blur effect
          backgroundColor: "rgba(255, 255, 255, 0.5)", // Semi-transparent background
          borderRadius: "8px", // Rounded corners for the glass effect
          boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)", // Shadow for depth
          mx: 3, // Horizontal margin
          p: 0.5, // Padding
          display: {
            xs: "normal",
            sm: "normal",
            md: "none",
            lg: "none",
            xl: "none",
          },
        }}
      >
        <Paper elevation={0} sx={{ backgroundColor: "transparent" }}>
          {" "}
          {/* Transparent Paper */}
          <Box mt={0.5} sx={{ display: "flex", alignItems: "center" }}>
            <AccountBalanceWalletIcon sx={{ color: "#FFD80A" }} />
            <Box ml={1}>
              {" "}
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <CurrencyRupeeIcon sx={{ fontSize: "1rem", color: "#fff" }} />
                <Typography
                  sx={{ fontSize: "0.8rem", color: "#fff", letterSpacing: 0.5 }}
                >
                  {Number(walletBalance).toFixed(2)}
                </Typography>
              </Box>
              <Box>
                <Typography sx={{ fontSize: "0.6rem", color: "#76ff03" }}>
                  Available Balance
                </Typography>
              </Box>
            </Box>
            <Box flexGrow={1} />
            <Box
              sx={{
                mr: 0.5,
                backgroundColor: "#ffd600",
                borderRadius: 1,
                p: 0.3,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => {
                setAddPointOpen(true);
                handleDrawerToggle(); // Close the drawer
              }}
            >
              <AddCircleIcon sx={{ color: "#000", fontSize: "0.8rem" }} />
              <Typography
                sx={{ color: "#000", fontSize: "0.6rem", fontWeight: "bold" }}
              >
                Add Point
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Box>

      <Box mt={0}>
        <List sx={{ mt: 1, mx: { xs: 4, sm: 2 } }}>
          {menuItems.map((item, index) => (
            <>
              <ListItem
                key={index + 1}
                sx={{
                  display: "block",
                  borderRadius: 3,
                  padding: { xs: 0.5, lg: "normal" },
                  background:
                    selectedIndex === index ? "#FFD80A" : "transparent",
                }}
                onClick={() => handleListItemClick(index)} // Toggle sub-items
              >
                <Link
                  to={item.path}
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={toggleExpand}
                >
                  {/* <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  > */}
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      // width: "20px",

                      mr: !mobileOpen ? 1.5 : 1,
                      ml: { xs: 1, md: 2 },
                      color: selectedIndex === index ? "#000000" : "#FFD80A",
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.text}
                    selected={selectedIndex === index}
                    sx={{
                      color: selectedIndex === index ? "#000000" : "#FFD80A",
                      fontWeight: selectedIndex === index ? "bold" : "normal",
                      mt: 0.8,
                    }}
                  />
                  {item.subItems ? (
                    // <ExpandMoreIcon
                    //   sx={{
                    //     color: selectedIndex === index ? "#1E89FF" : "#FFD80A",
                    //     ml: 1,
                    //   }}
                    // />
                    isExpanded ? (
                      <ExpandLessIcon
                        sx={{
                          color:
                            selectedIndex === index ? "#000000" : "#FFD80A",
                          ml: 1,
                          mt: 0.3,
                        }}
                      />
                    ) : (
                      <ExpandMoreIcon
                        sx={{
                          color:
                            selectedIndex === index ? "#000000" : "#FFD80A",
                          ml: 1,
                          mt: 0.3,
                        }}
                      />
                    )
                  ) : null}
                  {/* </Box> */}
                </Link>
              </ListItem>

              {item.subItems && selectedIndex === index && (
                <List>
                  {item.subItems.map((subItem, subIndex) => (
                    <ListItem key={subIndex + 1} sx={{ p: "0px 0px" }}>
                      <Link
                        to={subItem.path}
                        style={{
                          textDecoration: "none",
                          color: "inherit",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: !mobileOpen ? 1.5 : 1,
                            ml: { xs: 0, md: 6.5 },
                            color:
                              selectedIndex === index ? "#FFD80A" : "#FFD80A",
                          }}
                        >
                          {subItem.icon}
                        </ListItemIcon>
                        <ListItemText
                          primary={subItem.text}
                          selected={selectedIndex === subIndex}
                          sx={{
                            color:
                              selectedIndex === subIndex
                                ? "#000000"
                                : "#FFD80A",
                            fontWeight:
                              selectedIndex === subIndex ? "bold" : "normal",
                          }}
                        />
                      </Link>
                    </ListItem>
                  ))}
                </List>
              )}
            </>
          ))}
        </List>
      </Box>
      {/* <Box>
        <Typography sx={{ color: "white", textAlign: "center" }}>
          v_1.1 & 07-01-24
        </Typography>
      </Box> */}
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer - 1,
          backgroundColor: { xs: "#212762", sm: "#212762", md: "#f5f5f5" },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Grid container>
            <Grid item xs={0} sm={2}></Grid>
            <Grid item xs={12} sm={10}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: { xs: "space-between", md: "space-around" },

                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    color: { xs: "#ffffff", sm: "#ffffff", md: "#212762" },
                    fontSize: { xs: "12px", sm: "16px" },
                    display: { xs: "grid", md: "flex" },
                    // ml: { xs: 0, sm: 0, md: 2 },
                    mr: 6,
                    whiteSpace: "nowrap",
                    fontWeight: { xs: "none", sm: "bold" },
                    letterSpacing: 0.5,
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText(profileData?.userId);
                    toast.success("UserId Copied.");
                  }}
                >
                  User Id:{" "}
                  <Typography
                    sx={{
                      color: { xs: "orange", sm: "green", md: "green" },
                      fontWeight: { xs: "none", md: "bold" },
                      fontSize: { xs: "12px", sm: "16px" },
                      ml: { xs: 0, sm: 0, md: 0.5 },
                    }}
                  >
                    {profileData?.userId}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    color: { xs: "#ffffff", sm: "#ffffff", md: "#212762" },
                    fontSize: { xs: "12px", sm: "16px" },

                    display: { xs: "grid", md: "flex" },
                    alignItems: "center",
                    mr: 2,
                    whiteSpace: "nowrap",
                    fontWeight: { xs: "none", sm: "bold" },
                    letterSpacing: 0.5,
                  }}
                >
                  Available Points:{" "}
                  <Typography
                    sx={{
                      color: { xs: "#76ff03", md: "red" },
                      fontWeight: { xs: "none", md: "bold" },
                      fontSize: { xs: "12px", sm: "18px" },
                      whiteSpace: "nowrap",
                      ml: { xs: 0, sm: 0, md: 0.5 },
                      letterSpacing: 1,
                    }}
                  >
                    {Number(walletBalance).toFixed(2)}
                  </Typography>
                </Box>

                {/* notification */}
                <Box sx={{ mx: 2 }}>
                  <Notification />
                </Box>

                <Box
                  sx={{
                    "&:hover": {
                      background: { xs: "none", md: "#ffe0b2" },
                      borderRadius: { xs: 0, md: 4 },
                      px: { xs: 0, md: 1 },
                    },
                  }}
                >
                  <ProfileBadge />
                </Box>
                {/* <Box
                  sx={{
                    mt: { xs: 1, sm: 0 },
                    mr: { xs: -4, sm: 0 },
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  onClick={handleOpenLogoutDialog}
                >
                  <LogoutIcon sx={{ color: "#FFB320" }} />
                  <Box sx={{ display: { xs: "none", sm: "contents" } }}>
                    <Typography
                      sx={{
                        color: { sm: "#ffffff", md: "#212762" },
                        fontWeight: "bold",
                        "&:hover": {
                          color: "#5e68cd",
                        },
                      }}
                    >
                      Logout
                    </Typography>
                  </Box>
                </Box> */}
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box>
        <Menu
          anchorEl={walletMenuAnchor}
          open={Boolean(walletMenuAnchor)}
          onClose={handleCloseWalletMenu}
          anchorOrigin={{
            vertical: "bottom", // Align the menu to the bottom
            horizontal: "right", // Align the menu to the right
          }}
          transformOrigin={{
            vertical: "top", // Position the menu's top to the bottom of the anchor element
            horizontal: "right", // Align the right edges of the menu and the anchor
          }}
        >
          <MenuItem onClick={handleManagePointMenu}>Manage Points</MenuItem>
          {/* <MenuItem onClick={handleWithdrawPointMenu}>
            Withdraw Reports
          </MenuItem> */}
          {/* <MenuItem onClick={handleDepositPointMenu}>Deposit Reports</MenuItem> */}
        </Menu>

        <Modal
          open={addPointOpen}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 360,

              bgcolor: "background.paper",

              boxShadow: 24,
              p: 4,
              borderRadius: 1,
            }}
          >
            <RequestDialog />
          </Box>
        </Modal>
      </Box>
    </Box>
  );
}

export default Sidebar;
