import { Box, Typography } from "@mui/material";
import React from "react";
import Sidebar from "../../components/sidebar/sidebar";
import { Helmet } from "react-helmet";
import WalletStatements from "../../components/WalletStatements/WalletStatements";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CustomizedDatePickerForHistory from "../../components/History/DatePickerHistory";

function WalletStatementsPage() {
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>Satta-Matka ( M-India ) | Wallet_Statements</title>
      </Helmet>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Sidebar />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 1,
            background: `url(${process.env.PUBLIC_URL}/images/logo-background.png) center/250px no-repeat fixed `,
            minHeight: "100vh",
          }}
        >
          <Box height={{ xs: 60, md: 90 }} />

          <Box mx={{ xs: 1, sm: 2, md: 4 }}>
            <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
              <ArrowBackIcon
                sx={{
                  color: { xs: "#ffffff", md: "#1e2466" },
                  border: "1px solid #1e2466",
                  backgroundColor: { xs: "#1e2466", md: "transparent" },
                  borderRadius: 2,
                  "&:hover": {
                    backgroundColor: "#1e2466",
                    color: "#ffffff",
                  },
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate("/home/menus");
                }}
              />
              <Typography
                variant="h6"
                fontWeight={"bold"}
                color={"#1e2466"}
                ml={1}
              >
                Wallet Statements
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CustomizedDatePickerForHistory />
            </Box>
            <WalletStatements />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default WalletStatementsPage;
