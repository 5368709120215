// PageNotFound.js

import React from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Typography } from "@mui/material";
import "./404.css";

const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <>
      {/* <div style={{ textAlign: "center" }}>
        <img src="/images/404-page.gif" />
        <h1 style={{ color: "red" }}> Page Not Found </h1>
      </div>

      <Box
        sx={{
          textAlign: "center",
          my: { xs: 15, sm: 20 },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ArrowBackIcon
          sx={{
            border: "1px solid orange",
            borderRadius: "50%",
            backgroundColor: { xs: "orange", md: "white" },
            color: { xs: "#ffffff", md: "black" },
            p: 0.5,
            "&:hover": {
              backgroundColor: "orange",
              color: "#ffffff",
              cursor: "pointer",
            },
          }}
          onClick={() => navigate("/home/menus")}
        />
        <Typography variant="body1" ml={1}>
          Back to home
        </Typography>
      </Box> */}
      <section className="page_404">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 ">
              <div className="col-sm-12 col-sm-offset-1  text-center">
                <div className="four_zero_four_bg">
                  <h1 className="text-center ">404</h1>
                </div>

                <div className="contant_box_404">
                  <h3 className="h2">Looks like you're lost</h3>

                  <p>the page you are looking for is not avaible!</p>

                  <a href="/home/menus" className="link_404">
                    Go to Home
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PageNotFound;
