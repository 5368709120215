import * as React from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Box, TextField, Typography } from "@mui/material";
import { useAppStore } from "../../appStore";

export default function CustomizedDatePickerForHistory() {
  const { setSelectedDateHistory } = useAppStore();

  // Function to handle date change
  const handleDateChange = (date) => {
    const formattedDate = formatDate(date);
    setSelectedDateHistory(formattedDate);
    console.log(formattedDate);
  };

  // Function to format the date as 'DD-MM-YYYY'
  const formatDate = (dateString) => {
    const date = new Date(dateString); // Parse the date string into a Date object
    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;

    return formattedDate;
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: { xs: "100%", sm: "auto" }, // Full width on xs
        mb: 2,
      }}
    >
      <Typography
        sx={{
          mr: 1,
          display: { xs: "none", sm: "contents" },
          whiteSpace: "nowrap",
        }}
      >
        Choose Date:
      </Typography>{" "}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box sx={{ width: "100%" }}>
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
              onChange={handleDateChange}
              renderInput={(params) => <TextField {...params} sx={{ p: 0 }} />}
              format="DD/MM/YYYY"
            />
          </DemoContainer>
        </Box>
      </LocalizationProvider>
    </Box>
  );
}
